import { useState, useEffect, useRef } from "react";

export default function ScaleToWidth({
   contentWidth,
   contentHeight,
   children,
}) {
   const [windowSize, setWindowSize] = useState({});
   const container = useRef();

   const scale =
      container.current &&
      parseInt(window.getComputedStyle(container.current).width) / contentWidth;

   useEffect(() => {
      function handleResize() {
         setWindowSize({
            height: window.innerHeight,
            width: window.innerWidth,
         });
      }

      window.addEventListener("resize", handleResize);

      return (_) => {
         window.removeEventListener("resize", handleResize);
      };
   });

   useEffect(() => {
      setWindowSize({
         height: window.innerHeight,
         width: window.innerWidth,
      });
   }, []);

   return (
      <div
         style={{
            width: "100%",
            maxWidth: contentWidth,
            height: contentHeight * scale,
            overflow: "hidden",
         }}
         ref={container}
      >
         {scale && (
            <div
               style={{
                  transform: "scale(" + scale + ")",
                  transformOrigin: "top left",
                  position: "absolute",
               }}
            >
               <div
                  style={{
                     position: "absolute",
                     width: contentWidth - 2,
                     height: contentHeight - 2,
                  }}
               />
               {children({ scale })}
            </div>
         )}
      </div>
   );
}
