import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";

import Home from "./Home";

import Events from "./Events";
import Editor from "./Editor";
import Producer from "./Producer";
import Import from "./Import";
import ViewerEditor from "./ViewerEditor";
import ViewerL3Editor from "./ViewerL3Editor";
import ViewerThanksEditor from "./ViewerThanksEditor";

import Wrapper from "../../components/Wrapper";

export default function RoutesPrivate() {
   return (
      <Routes>
         <Route
            path='/'
            element={
               <Wrapper>
                  <Events />
               </Wrapper>
            }
         />

         <Route
            path='/client/:eventId'
            element={
               <Wrapper>
                  <Editor />
               </Wrapper>
            }
         />
         <Route
            path='/dashboard/:eventId'
            element={
               <Wrapper>
                  <Producer />
               </Wrapper>
            }
         />

         <Route path='/import' element={<Import />} />
         <Route
            path='/viewerthankseditor/:eventId'
            element={<ViewerThanksEditor />}
         />
         <Route path='/viewereditor/:eventId' element={<ViewerEditor />} />
         <Route path='/viewerl3editor/:eventId' element={<ViewerL3Editor />} />
      </Routes>
   );
}
