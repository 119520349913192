import React, { useState, useEffect } from "react";

export const StylesContext = React.createContext();
export const StylesContextProvider = ({ children }) => {
   const [activeElement, setActiveElement] = useState();
   const [layout, setLayout] = useState([]);
   const [layoutFields, setLayoutFields] = useState([]);

   const [grid, setGrid] = useState(1);
   const [lockAspectRatio, setLockAspectRatio] = useState(false);

   const [lastUpdate, setLastUpdate] = useState(Date.now());

   useEffect(() => {
      // console.log(layoutFields);
   }, [layoutFields]);

   return (
      <StylesContext.Provider
         value={{
            layout,
            setLayout,
            layoutFields,
            setLayoutFields,
            activeElement,
            setActiveElement,
            grid,
            setGrid,
            lockAspectRatio,
            setLockAspectRatio,
            lastUpdate,
            setLastUpdate,
         }}
      >
         {children}
      </StylesContext.Provider>
   );
};
