import React, { useState, useEffect, useRef } from "react";
import { saveAs } from "file-saver";
import axios from "axios";
import { PNG } from "pngjs/browser";

import { Box, Stack, Button, TextField, Typography } from "@mui/material";
import { MuiFileInput } from "mui-file-input";

import ScaleToWidth from "./ScaleToWidth.js";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function Canvas(props) {
   const canvasRef = useRef(null);

   const downloadImage = () => {
      canvasRef.current.toBlob(function (blob) {
         saveAs(blob, "DownloadedImage.png");
      }, "image/png");
   };

   const uploadImage = () => {
      canvasRef.current.toBlob((blob) => {
         var formData = new FormData();

         formData.append(
            "fileName",
            props.location + "-" + props.eventId + "-" + Date.now()
         );

         formData.append("EntryFile", blob);

         axios
            .post(
               SERVER_URL +
                  "/api/uploadImage/" +
                  props.eventId +
                  "/" +
                  props.location,
               formData,
               {
                  headers: {
                     "Content-Type": "multipart/form-data",
                  },
               }
            )
            .then(function (response) {
               props.handleResponse(response);
            })
            .catch((error) => {
               if (error.toJSON().message === "Network Error") {
                  alert(
                     "Could not upload image, there has been a network error.  Please make sure the ATEM is connected, check your network connection, and try again."
                  );
               } else {
                  alert(error.toJSON().message);
               }
            });
      }, "image/png");
   };

   useEffect(() => {
      const context = canvasRef.current.getContext("2d");

      const image = new Image();
      image.src = props.image;

      const imageRatio = props.imgHeight / props.imgWidth;

      let drawSize = {};

      if (imageRatio > 0.5625) {
         drawSize.width = (1080 / props.imgHeight) * props.imgWidth;
         drawSize.height = 1080;
         drawSize.x = (1920 - drawSize.width) / 2;
         drawSize.y = 0;
      } else {
         drawSize.width = 1920;
         drawSize.height = (1920 / props.imgWidth) * props.imgHeight;
         drawSize.x = 0;
         drawSize.y = (1080 - drawSize.height) / 2;
      }

      image.onload = () => {
         context.drawImage(
            image,
            drawSize.x,
            drawSize.y,
            drawSize.width,
            drawSize.height
         );
         uploadImage();

         URL.revokeObjectURL(image.src);
      };
   }, []);

   return (
      <>
         <Box sx={{ position: "relative", width: 1920, height: 1080 }}>
            <canvas
               style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: 1920,
                  height: 1080,
                  zIndex: 10,
               }}
               ref={canvasRef}
               width={props.width}
               height={props.height}
               imageWidth={props.imgWidth}
               imageHeight={props.imgHeight}
            />
         </Box>
      </>
   );
}

export default function Uploader({ eventId, location, handleResponse }) {
   const [image, setImage] = useState(null);
   const [imageSize, setImageSize] = useState(null);
   const [file, setFile] = React.useState(null);

   const handleImageLoad = (image) => {
      var img = new Image();
      img.src = URL.createObjectURL(image);

      img.onload = () => {
         setImage(img.src);
         setImageSize({ width: img.naturalWidth, height: img.naturalHeight });
      };
   };

   const handleFileChange = (newFile) => {
      if (newFile) {
         setFile(newFile);
      } else {
         setFile(null);
         setImage(null);
      }
   };

   useEffect(() => {
      if (file) {
         handleImageLoad(file);
      }
   }, [file]);

   return (
      <Stack spacing={2} alignItems='center' sx={{ pb: 4 }}>
         <Box>
            <MuiFileInput
               fullWidth
               sx={{
                  border: "1px solid rgba(255,0,0,.8)",
                  borderRadius: 1,
               }}
               placeholder={"UPLOAD IMAGE"}
               value={file}
               onChange={handleFileChange}
               inputProps={{ accept: "image/png, image/jpeg, image/jpg" }}
            />
            <Box
               sx={{
                  fontStyle: "italic",
                  fontSize: ".8em",
                  color: "rgba(255,255,255,.5)",
                  textAlign: "center",
                  my: 1,
               }}
            >
               Upload JPG or PNG
               <br />
               (Auto-resizes to 1920x1080)
            </Box>
         </Box>

         <Box sx={{ display: "none", position: "relative", width: "100%" }}>
            <ScaleToWidth contentWidth={1920} contentHeight={1080}>
               {(parent) => (
                  <>
                     {image && imageSize && (
                        <Canvas
                           key={image}
                           width={1920}
                           height={1080}
                           image={image}
                           imgHeight={imageSize.height}
                           imgWidth={imageSize.width}
                           scale={parent.scale}
                           eventId={eventId}
                           location={location}
                           handleResponse={handleResponse}
                           title={file.name.split(".")[0]}
                        />
                     )}

                     <Box
                        className='transparentBG'
                        sx={{
                           position: "absolute",
                           top: 0,
                           left: 0,
                           width: 1920,
                           height: 1080,
                           outline: "8px solid rgba(255,255,255,.2)",
                        }}
                     />
                  </>
               )}
            </ScaleToWidth>
         </Box>
      </Stack>
   );
}
