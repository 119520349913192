import React, { useState, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@clerk/clerk-react";
import _ from "lodash";

import { StylesContextProvider } from "../../context/editorStyles";
import Urls from "../../components/Urls";

import ScaleToWidth from "../../components/ScaleToWidth.js";
import BidderList from "../../components/BidderList.js";
import RecentBidderList from "../../components/RecentBidderList.js";
import BidderSearch from "../../components/BidderSearch.js";
import Import from "./Import.js";

import Viewport from "../../components/Viewport";

import DashboardMenu from "../../components/DashboardMenu.js";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";

import Uploader from "../../components/Uploader";

import useProducer from "../../hooks/useProducer";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function Producer() {
   const navigate = useNavigate();

   const [
      eventId,
      bidders,
      biddersById,
      activeBidder,
      recentBidders,
      eventExists,
      eventTitle,
      logo,
      setLogo,
      setActiveBidder,
      {
         joinRoom,
         getBidders,
         getRecentBidders,
         getEvent,
         updateActiveSlide,
         clearRecentBidders,
         updateBidderInfo,
      },
   ] = useProducer();

   const previewWidth = useRef();

   // EFFECTS

   useEffect(() => {
      joinRoom(eventId);
      getBidders(eventId);
      getRecentBidders(eventId);
      getEvent(eventId);
   }, []);

   useEffect(() => {
      if (bidders && bidders[activeBidder]) {
         let payload = {
            type: "bidder",
            eventId: eventId,
            data: bidders[activeBidder],
         };
         updateActiveSlide(payload);
      }
   }, [activeBidder]);

   useEffect(() => {
      function handleResize() {}

      window.addEventListener("resize", handleResize);

      return (_) => {
         window.removeEventListener("resize", handleResize);
      };
   });

   // MODAL

   const [openImport, setOpenImport] = useState(false);
   const handleImportOpen = (name) => {
      setOpenImport(true);
   };
   const handleImportClose = () => {
      setOpenImport(false);
   };

   const modalImportStyle = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "80%",
      height: "80%",
      bgcolor: "#333",
      outline: 0,
      border: "4px solid #111",
      borderRadius: 5,
      boxShadow: 24,
      p: 8,
      boxSizing: "border-box",
      backgroundColor: "#222",
      overflow: "hidden",
   };

   // FUNCTIONS

   const handleResponse = (response) => {
      setLogo({
         src: response.data.file,
         lastUpdate: Date.now(),
      });
   };

   const commitBidder = (num) => {
      setActiveBidder("");
      setTimeout(function () {
         setActiveBidder(num);
      }, 100);
   };

   const setSlide = (type) => {
      updateActiveSlide({ type: type, eventId: eventId });
   };

   return (
      <>
         <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
         >
            <Grid item xs={4}>
               <Typography
                  variant='h4'
                  gutterBottom
                  component='div'
                  sx={{ m: 0 }}
               >
                  {eventTitle}
               </Typography>
            </Grid>
            <Grid item xs={8}>
               <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='flex-end'
                  spacing={2}
                  sx={{ width: "100%" }}
               >
                  <Urls eventId={eventId} />

                  <DashboardMenu
                     handleImportOpen={handleImportOpen}
                     downloadCSV={SERVER_URL + "/api/saveDB/" + eventId}
                  />
               </Stack>
            </Grid>
         </Grid>

         {eventExists && (
            <Grid
               container
               direction='row'
               justifyContent='center'
               alignItems='flex-start'
               spacing={3}
               sx={{ mt: 1 }}
            >
               <Grid item xs={12}>
                  <BidderSearch
                     bidders={bidders}
                     setSlide={setSlide}
                     commitBidder={commitBidder}
                     callback={commitBidder}
                  />
               </Grid>

               <Grid item xs={5}>
                  <Grid container spacing={2} alignItems='center'>
                     <Grid item xs>
                        FULLSCREEN
                     </Grid>
                     <Grid item>
                        <Button
                           variant='outlined'
                           color='error'
                           size='small'
                           onClick={() =>
                              navigate("/admin/viewereditor/" + eventId)
                           }
                        >
                           Edit Layout
                        </Button>
                     </Grid>
                  </Grid>

                  <Box
                     sx={{
                        mt: 2,
                        overflow: "hidden",
                        position: "relative",
                     }}
                  >
                     <ScaleToWidth contentWidth={1920} contentHeight={1080}>
                        {(parent) => (
                           <Box
                              width='1920'
                              height='1080'
                              scrolling='no'
                              className='transparentBG'
                              style={{
                                 border: "3px solid #333",
                                 outline: 0,
                                 width: 1920,
                                 height: 1080,
                              }}
                           >
                              <StylesContextProvider>
                                 <Viewport
                                    edit={false}
                                    previewScale={1}
                                    viewerLayout={"fullscreen"}
                                 />
                              </StylesContextProvider>
                           </Box>
                        )}
                     </ScaleToWidth>
                  </Box>

                  <Stack
                     sx={{
                        mt: 2,
                        py: 2,
                        backgroundColor: "rgba(0,0,0,.2)",
                        borderRadius: 2,
                     }}
                  >
                     <Box sx={{ px: 2 }}>
                        <b>BIDDER LIST</b>
                     </Box>

                     <Box sx={{ mt: 2, overflowY: "auto" }}>
                        {bidders && (
                           <BidderList
                              key={"bidderList"}
                              bidders={biddersById}
                              commitBidder={commitBidder}
                              updateBidderInfo={updateBidderInfo}
                           />
                        )}
                     </Box>
                  </Stack>
               </Grid>
               <Grid item xs={5}>
                  <Grid container spacing={2} alignItems='center'>
                     <Grid item xs>
                        LOWER THIRD
                     </Grid>
                     <Grid item>
                        <Button
                           variant='outlined'
                           color='error'
                           size='small'
                           onClick={() =>
                              navigate("/admin/viewerl3editor/" + eventId)
                           }
                        >
                           Edit Layout
                        </Button>
                     </Grid>
                  </Grid>

                  <Box
                     sx={{
                        mt: 2,
                        height:
                           previewWidth.current &&
                           previewWidth.current.offsetWidth * 0.5625,
                        overflow: "hidden",
                     }}
                  >
                     <ScaleToWidth contentWidth={1920} contentHeight={1080}>
                        {(parent) => (
                           <Box
                              width='1920'
                              height='1080'
                              scrolling='no'
                              className='transparentBG'
                              style={{
                                 border: "3px solid #333",
                                 outline: 0,
                                 width: 1920,
                                 height: 1080,
                              }}
                           >
                              <StylesContextProvider>
                                 <Viewport
                                    edit={false}
                                    previewScale={1}
                                    viewerLayout={"lowerthird"}
                                 />
                              </StylesContextProvider>
                           </Box>
                        )}
                     </ScaleToWidth>
                  </Box>
                  <Stack
                     sx={{
                        mt: 2,
                        py: 2,
                        backgroundColor: "rgba(0,0,0,.2)",
                        borderRadius: 2,
                     }}
                  >
                     <Box sx={{ px: 2 }}>
                        <b>RECENT BIDDERS</b>
                     </Box>

                     {recentBidders.length === 0 && (
                        <Box sx={{ mt: 2, px: 2 }}>No recent bidders.</Box>
                     )}
                     {recentBidders.length > 0 && (
                        <Box sx={{ px: 2 }}>
                           <Button
                              variant='outlined'
                              color='info'
                              size='small'
                              sx={{ mt: 2, mb: 1 }}
                              onClick={() => clearRecentBidders()}
                              fullWidth
                           >
                              Clear
                           </Button>
                           <Box sx={{ mt: 2, overflow: "auto" }}>
                              <RecentBidderList
                                 key={"recentBidderList"}
                                 bidders={bidders}
                                 recentBidders={recentBidders}
                                 commitBidder={commitBidder}
                              />
                           </Box>
                        </Box>
                     )}
                  </Stack>
               </Grid>
               <Grid item xs={2}>
                  <Stack spacing={2}>
                     <Box sx={{ pb: 0.8 }}>THANK YOU</Box>
                     <Box
                        onClick={() => setSlide("thanks")}
                        sx={{
                           cursor: "pointer",
                           height:
                              previewWidth.current &&
                              previewWidth.current.offsetWidth * 0.5625,
                           overflow: "hidden",
                        }}
                     >
                        <ScaleToWidth contentWidth={1920} contentHeight={1080}>
                           {(parent) => (
                              <Box
                                 width='1920'
                                 height='1080'
                                 scrolling='no'
                                 className='transparentBG'
                                 style={{
                                    border: "3px solid #333",
                                    outline: 0,
                                    width: 1920,
                                    height: 1080,
                                 }}
                              >
                                 <StylesContextProvider>
                                    <Viewport
                                       edit={false}
                                       activeSlideOverride={"thanks"}
                                       previewScale={1}
                                       viewerLayout={"thanks"}
                                    />
                                 </StylesContextProvider>
                              </Box>
                           )}
                        </ScaleToWidth>
                     </Box>
                     <Button
                        sx={{ float: "right" }}
                        variant='outlined'
                        color='error'
                        size='small'
                        onClick={() =>
                           navigate("/admin/viewerthankseditor/" + eventId)
                        }
                     >
                        Edit Thank You
                     </Button>
                     <Box
                        sx={{
                           pt: 2,
                        }}
                     >
                        LOGO
                     </Box>
                     <Box
                        onClick={() => setSlide("logo")}
                        sx={{
                           cursor: "pointer",
                        }}
                     >
                        <img
                           src={SERVER_URL + "/uploads/" + logo.src}
                           width='100%'
                           alt='Logo Graphic'
                        />
                     </Box>
                     <Box>
                        <Uploader
                           eventId={eventId}
                           location={"logo"}
                           handleResponse={handleResponse}
                        />
                     </Box>
                  </Stack>
               </Grid>
            </Grid>
         )}

         <Modal
            open={openImport}
            onClose={handleImportClose}
            aria-labelledby='modal-title'
            aria-describedby='modal-upload'
         >
            <Box sx={modalImportStyle}>
               <Typography id='modal-title' variant='h6' component='h2'>
                  Import CSV
               </Typography>
               <Typography id='modal-upload' sx={{ pt: 2, overflow: "hidden" }}>
                  <Import close={handleImportClose} eventId={eventId} />
               </Typography>
            </Box>
         </Modal>
      </>
   );
}
