import React, { useState, useEffect } from "react";
import { useParams } from "react-router";

import { StylesContextProvider } from "../../context/editorStyles";

import Viewport from "../../components/Viewport";
import EditorSidebar from "../../components/EditorSidebar";

export default function ViewerL3Editor() {
   // RESIZE LISTENER
   const [previewScale, setPreviewScale] = useState(
      (window.innerWidth - 240) / 1920
   );

   useEffect(() => {
      function handleResize() {
         setPreviewScale((window.innerWidth - 240) / 1920);
      }

      window.addEventListener("resize", handleResize);

      return (_) => {
         window.removeEventListener("resize", handleResize);
      };
   });

   const { eventId } = useParams();

   return (
      <StylesContextProvider>
         <EditorSidebar eventId={eventId} viewerLayout={"lowerthird"}>
            <Viewport
               edit={true}
               previewScale={previewScale}
               viewerLayout={"lowerthird"}
            />
         </EditorSidebar>
      </StylesContextProvider>
   );
}
