import { useState, useEffect } from "react";

import Grid from "@mui/material/Grid";
import Slider from "@mui/material/Slider";
import Input from "@mui/material/Input";

import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

export default function InputSlider({ label, value, inputOptions, callback }) {
   const [inputValue, setInputValue] = useState(value);

   useEffect(() => {
      callback(inputValue);
   }, [inputValue]);

   const handleSliderChange = (event, newValue) => {
      setInputValue(newValue);
   };

   const handleInputChange = (event) => {
      setInputValue(
         event.target.value === "" ? "" : Number(event.target.value)
      );
   };

   const handleBlur = () => {
      if (value < inputOptions.min) {
         setInputValue(inputOptions.min);
      } else if (value > inputOptions.max) {
         setInputValue(inputOptions.max);
      }
   };

   return (
      <Grid
         container
         alignItems='center'
         sx={{ textAlign: "left", width: "100%", color: "#fff" }}
      >
         <Grid item xs={6} sx={{ pr: 1 }}>
            <Input
               fullWidth
               value={inputValue}
               onChange={handleInputChange}
               onBlur={handleBlur}
               inputProps={{
                  step: inputOptions.step,
                  min: inputOptions.min,
                  max: inputOptions.max,
                  type: "number",
                  "aria-labelledby": "input-slider",
               }}
               endAdornment={
                  <IconButton
                     onClick={() =>
                        setInputValue(Math.max(inputOptions.min, 0))
                     }
                  >
                     <ClearIcon />
                  </IconButton>
               }
            />
         </Grid>
         <Grid item xs={6}>
            <Slider
               step={inputOptions.step}
               min={inputOptions.min}
               max={inputOptions.max}
               value={inputValue}
               onChange={handleSliderChange}
            />
         </Grid>
      </Grid>
   );
}
