import { useState, useContext, useMemo, useEffect } from "react";
import { SocketContext } from "../context/socket";

import { useParams } from "react-router";

import axios from "axios";
import _ from "lodash";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function useProducer() {
   const { eventId } = useParams();
   const socket = useContext(SocketContext);

   const [bidders, setBidders] = useState();
   const [biddersById, setBiddersById] = useState();
   const [activeBidder, setActiveBidder] = useState("");
   const [recentBidders, setRecentBidders] = useState([]);

   const [eventExists, setEventExists] = useState(false);
   const [eventTitle, setEventTitle] = useState();
   const [logo, setLogo] = useState({ src: "", lastUpdate: "" });

   // LISTENERS
   socket.on("refresh", () => {
      socket.emit("getRecentBidders", eventId, (array) => {
         setRecentBidders(array);
      });
   });

   socket.on("refreshBidderList", () => {
      socket.emit("getBidders", eventId, (payload) => {
         setBidders(_.keyBy(payload, "bidderNum"));
         setBiddersById(_.keyBy(payload, "id"));
      });

      socket.emit("getRecentBidders", eventId, (array) => {
         setRecentBidders(array);
      });
   });

   socket.on("recentBidders", (array) => {
      setRecentBidders(array);
   });

   const handlers = useMemo(
      () => ({
         joinRoom: () => {
            socket.emit("joinRoom", eventId);
         },
         getBidders: () => {
            socket.emit("getBidders", eventId, (payload) => {
               setBidders(_.keyBy(payload, "bidderNum"));
               setBiddersById(_.keyBy(payload, "id"));
            });
         },
         getRecentBidders: () => {
            socket.emit("getRecentBidders", eventId, (array) => {
               setRecentBidders(array);
            });
         },
         getEvent: () => {
            socket.emit("getEvent", eventId, (event) => {
               if (event) {
                  setEventExists(true);
                  setEventTitle(event.name);

                  setLogo({
                     src: _.find(event.styles, function (style) {
                        return (
                           style.name === "background" && style.type === "logo"
                        );
                     }).image,
                     lastUpdate: Date.now(),
                  });
               } else {
                  setEventTitle("This event doesn't exist!");
               }
            });
         },
         setRecentBidders: (array) => {
            setRecentBidders(array);
         },
         updateBidderInfo: (bidderEditInfo) => {
            socket.emit("updateBidderInfo", bidderEditInfo);
         },
         updateActiveSlide: (payload) => {
            socket.emit("updateActiveSlide", payload);
         },
         clearRecentBidders: () => {
            socket.emit("clearRecentBidders", eventId);
         },
      }),
      [socket]
   );

   return [
      eventId,
      bidders,
      biddersById,
      activeBidder,
      recentBidders,
      eventExists,
      eventTitle,
      logo,
      setLogo,
      setActiveBidder,
      handlers,
   ];
}
