import React, { useRef, useState } from "react";
import { Button, Stack, TextField } from "@mui/material";

import BidderInfo from "./BidderInfo.js";

export default function BidderSearch({
   bidders,
   setSlide,
   commitBidder,
   callback,
}) {
   const inputRef = useRef();
   const [bidderNum, setBidderNum] = useState("");

   const handleUpdate = (e) => {
      let text = e.target.value.replace(/[^0-9]/g, "");
      setBidderNum(text.slice(-3));
   };

   const handleKeyPress = (e) => {
      let text = e.target.value.replace(/[^0-9]/g, "");

      if (e.key === "Enter") {
         commitBidder(text);
         handleClearInput();
      }

      if (e.key === "*") {
         setSlide("logo");
      }

      if (e.key === "/") {
         setSlide("thanks");
      }
   };

   function handleClearInput() {
      inputRef.current.focus();
      inputRef.current.value = "";
      setBidderNum();
   }

   function handleSetSlide(value) {
      setSlide(value);
      // handleClearInput();
   }

   return (
      <Stack
         direction='row'
         spacing={2}
         alignItems='center'
         justifyContent='space-between'
         sx={{ p: 2, backgroundColor: "rgba(0,0,0,.4)", borderRadius: 2 }}
      >
         <Stack
            direction='row'
            spacing={2}
            alignItems='center'
            justifyContent='flex-start'
         >
            <TextField
               key='biddernumber'
               inputRef={inputRef}
               id='biddernumber'
               value={bidderNum}
               label='Bidder Number'
               variant='outlined'
               onKeyPress={handleKeyPress}
               onChange={handleUpdate}
            />

            <Button
               variant='contained'
               color='success'
               onClick={() => {
                  handleClearInput();
                  commitBidder(bidderNum);
               }}
            >
               ACTIVATE
            </Button>

            {bidders &&
               bidderNum &&
               bidderNum.length === 3 &&
               !bidders[bidderNum] && (
                  <b>No Bidder Information for {bidderNum}</b>
               )}

            {bidders && bidderNum && bidders[bidderNum] && (
               <BidderInfo data={bidders[bidderNum]} />
            )}
         </Stack>

         <Stack>
            <Button
               fullWidth
               variant='outlined'
               color='secondary'
               onClick={() => handleSetSlide("thanks")}
               sx={{ mb: 1 }}
            >
               SHOW THANK YOU
            </Button>

            <Button
               fullWidth
               variant='outlined'
               color='secondary'
               onClick={() => handleSetSlide("logo")}
               sx={{ mb: 1 }}
            >
               SHOW LOGO
            </Button>
         </Stack>
      </Stack>
   );
}
