import * as React from "react";
import Box from "@mui/material/Box";
import ListItemText from "@mui/material/ListItemText";

// LANGUAGE FOR LABELS
import { language } from "./Language";

export default function SidebarWrapper({ children, label, type }) {
   return (
      <>
         <ListItemText
            primary={
               <Box
                  sx={{
                     backgroundColor: "#222",
                     px: 2,
                     py: 0.5,
                     fontWeight: "bold",
                  }}
               >
                  {language[label]}
               </Box>
            }
            secondary={
               <Box
                  sx={{
                     px: 2,
                     pt: 1,
                     pb: 2,
                     fontWeight: "bold",
                  }}
               >
                  {children}
               </Box>
            }
         />
      </>
   );
}
