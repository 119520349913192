import { useState, useContext, useMemo, useEffect } from "react";
import { SocketContext } from "../context/socket";

import axios from "axios";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function useEvents() {
   const socket = useContext(SocketContext);

   const [events, setEvents] = useState([]);

   const handlers = useMemo(
      () => ({
         getAllEvents: () => {
            socket.emit("getAllEvents", (events) => {
               setEvents(events);
            });
         },
         addEvent: (title) => {
            socket.emit("addEvent", title, (events) => {
               setEvents(events);
            });
         },
         updateEvent: (eventTitle, eventId) => {
            socket.emit("updateEvent", eventTitle, eventId, (events) => {
               setEvents(events);
            });
         },
         deleteEvent: (eventid) => {
            socket.emit("deleteEvent", eventid, (events) => {
               setEvents(events);
            });
         },
      }),
      [socket]
   );

   return [events, handlers];
}
