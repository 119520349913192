import React, { useState } from "react";

import {
   BrowserRouter as Router,
   Routes,
   Route,
   useNavigate,
} from "react-router-dom";

import Home from "./Home";

// CLERK
import { SignIn, SignUp } from "@clerk/clerk-react";

export default function RoutesPrivate() {
   const navigate = useNavigate();

   return (
      <Routes>
         <Route path='/' element={<Home />} />
         <Route
            path='/sign-in/*'
            element={<SignIn routing='path' path='/sign-in' />}
         />
         <Route
            path='/sign-up/*'
            element={<SignUp routing='path' path='/sign-up' />}
         />
      </Routes>
   );
}
