import { useState, useEffect } from "react";
import { Rnd } from "react-rnd";
import { motion } from "framer-motion";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import FitScreenIcon from "@mui/icons-material/FitScreen";
import VerticalAlignCenterIcon from "@mui/icons-material/VerticalAlignCenter";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";

const Handle = (rotate) => {
   return (
      <>
         <ArrowCircleRightIcon
            sx={{
               color: "#fff",
               transform: "rotate(" + (rotate ? rotate.angle : 0) + "deg)",
            }}
         />
      </>
   );
};

const HandleClasses = {
   bottom: "bottom",
   bottomLeft: "bottomLeft",
   bottomRight: "bottomRight",
   left: "left",
   right: "right",
   top: "top",
   topLeft: "topLeft",
   topRight: "topRight",
};

const HandleComponents = {
   top: <Handle />,
   right: <Handle />,
   bottom: <Handle />,
   left: <Handle />,
   topRight: <Handle />,
   bottomRight: <Handle />,
   bottomLeft: <Handle />,
   topLeft: <Handle />,
};

export default function RndContainer({
   children,
   data,
   zIndex,
   callback,
   minSize,
   scale,
   handleAdjust,
   handleActiveElement,
   grid,
   lockAspectRatio,
}) {
   const [coords, setCoords] = useState(data.position);

   useEffect(() => {
      setCoords(data.position);
   }, [data]);

   function roundDownToNearestGrid(num) {
      return Math.floor(num / grid) * grid;
   }

   const handleResize = (e, handle, ref, size, position) => {
      // UPDATE STATE
      let newPosition = { ...data.position };

      newPosition.x = parseFloat(position.x.toFixed(0));
      newPosition.y = parseFloat(position.y.toFixed(0));
      newPosition.width = parseInt(ref.style.width);
      newPosition.height = parseInt(ref.style.height);

      callback({
         id: data.id,
         name: data.name,
         column: "position",
         updates: { ...newPosition },
      });
   };

   const handleMove = (e, position) => {
      // UPDATE STATE
      let newPosition = { ...data.position };
      newPosition.x = roundDownToNearestGrid(parseFloat(position.x.toFixed(0)));
      newPosition.y = roundDownToNearestGrid(parseFloat(position.y.toFixed(0)));

      callback({
         id: data.id,
         name: data.name,
         column: "position",
         updates: { ...newPosition },
      });
   };

   const handleCoordsResize = (e, handle, ref, size, position) => {
      setCoords((prevState) => {
         let newState = { ...prevState };

         newState.x = position.x;
         newState.y = position.y;
         newState.width = parseInt(ref.style.width);
         newState.height = parseInt(ref.style.height);

         return newState;
      });
   };

   const handleCoordsMove = (e, position) => {
      setCoords((prevState) => {
         let newState = { ...prevState };

         newState.x = roundDownToNearestGrid(position.x);
         newState.y = roundDownToNearestGrid(position.y);

         return newState;
      });
   };

   // FRAMER MOTION
   const rndVariants = {
      visible: {
         opacity: 1,
         outline: "2px solid rgba(255,0,0,.3)",
      },
      hover: {
         opacity: 1,
         outline: "2px solid rgba(255,0,0,.8)",
      },
   };
   const rndInfoVariants = {
      visible: {
         opacity: 0,
      },
      hover: {
         opacity: 0.8,
      },
   };

   return (
      <>
         {data && (
            <Rnd
               as={motion.div}
               size={{
                  width: data.position.width,
                  height: data.position.height,
               }}
               scale={scale}
               minWidth={minSize ? minSize.width : 100}
               minHeight={minSize ? minSize.height : 50}
               position={{ x: data.position.x, y: data.position.y }}
               lockAspectRatio={lockAspectRatio}
               onResizeStop={handleResize}
               onResize={handleCoordsResize}
               onDragStop={handleMove}
               onDrag={handleCoordsMove}
               onClick={() => handleActiveElement(data)}
               style={{
                  position: "absolute",
                  boxSizing: "border-box",
                  padding: 0,
                  margin: 0,
                  fontFamily: '"Arial", sans-serif',
               }}
               className='rndComponent'
               initial='visible'
               whileHover='hover'
               variants={rndVariants}
               resizeHandleWrapperClass={"handles"}
               resizeHandleComponent={HandleComponents}
               resizeHandleClasses={HandleClasses}
            >
               {/* OVERLAY CONTROLS */}

               <Grid
                  container
                  alignItems='center'
                  justifyContent='flex-end'
                  as={motion.div}
                  style={{
                     position: "absolute",
                     top: 5,
                     right: 5,
                     transform: "scale(.7)",
                     transformOrigin: "top right",
                     width: "100%",
                     color: "#fff",
                     zIndex: 2000,
                     fontSize: 12,
                     fontWeight: "bold",
                     pointerEvents: "none",
                     whiteSpace: "nowrap",
                  }}
                  spacing={1}
                  variants={rndInfoVariants}
               >
                  <Grid item>
                     <Button
                        startIcon={<FitScreenIcon />}
                        color='error'
                        size='small'
                        variant='contained'
                        as={motion.div}
                        variants={rndInfoVariants}
                        sx={{
                           display: "flex",
                           justifyContent: "center",
                           alignItems: "center",
                           pointerEvents: "all",
                           zIndex: 200,
                           cursor: "pointer",
                        }}
                        onClick={() => handleAdjust(data.name, "fit")}
                     />
                  </Grid>
                  <Grid item>
                     <Button
                        startIcon={
                           <VerticalAlignCenterIcon
                              sx={{ transform: "rotate(90deg)" }}
                           />
                        }
                        size='small'
                        color='error'
                        variant='contained'
                        as={motion.div}
                        variants={rndInfoVariants}
                        sx={{
                           display: "flex",
                           justifyContent: "center",
                           alignItems: "center",
                           pointerEvents: "all",
                           zIndex: 200,
                           cursor: "pointer",
                        }}
                        onClick={() => handleAdjust(data.name, "center")}
                     />
                  </Grid>
               </Grid>

               {/* DATA */}
               <Grid
                  container
                  alignItems='center'
                  justifyContent='center'
                  as={motion.div}
                  style={{
                     position: "absolute",
                     bottom: -55,
                     right: 0,
                     height: 50,
                     transform: "scale(.7)",
                     transformOrigin: "top right",
                     width: 300,
                     minWidth: 300,
                     backgroundColor: "rgba(0,0,0,.4)",
                     borderRadius: 10,
                     color: "#fff",
                     zIndex: -1,
                     fontSize: 12,
                     fontWeight: "bold",
                     fontFamily: "Arial",
                     whiteSpace: "nowrap",
                  }}
                  variants={rndInfoVariants}
               >
                  <Grid item sx={{ p: 0.5 }}>
                     x: {parseInt(coords.x)}
                  </Grid>
                  <Grid item sx={{ p: 0.5 }}>
                     y: {parseInt(coords.y)}
                  </Grid>
                  <Grid item sx={{ p: 0.5 }}>
                     w: {parseInt(coords.width)}
                  </Grid>
                  <Grid item sx={{ p: 0.5 }}>
                     h: {parseInt(coords.height)}
                  </Grid>
               </Grid>

               <Box
                  sx={{
                     position: "absolute",
                     bottom: 5,
                     left: 5,
                     color: "rgba(255,0,0,.5)",
                     textTransform: "uppercase",
                  }}
               >
                  {data.name}
               </Box>

               <Box
                  sx={{
                     ...data.parameters,
                     width: "100%",
                     height: "100%",
                  }}
               >
                  {children}
               </Box>
            </Rnd>
         )}
      </>
   );
}
