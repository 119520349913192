import Typography from "@mui/material/Typography";

export default function BidderInfo(props) {
   return (
      <>
         <Typography variant='h5' component='h5'>
            <b style={{ marginRight: 20 }}>{props.data.bidderNum}</b>
            {props.data.name}
         </Typography>
      </>
   );
}
