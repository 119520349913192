import React, { useEffect } from "react";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import Debug from "../components/Debug";

import {
   SignInButton,
   SignOutButton,
   SignedIn,
   SignedOut,
   UserButton,
} from "@clerk/clerk-react";

import logo from "../images/pdlogo.png";

// THEME
const globalTheme = createTheme({
   palette: {
      mode: "dark",
      primary: {
         main: "#3a82e4",
      },
      secondary: {
         main: "#40b2a4",
      },
      info: {
         main: "#aaa7a0",
      },
      warning: {
         main: "#eeca4a",
      },
      success: {
         main: "#085856",
      },
      error: {
         main: "#d25238",
      },
      background: {
         default: "#111",
      },
      text: {
         primary: "#ccc",
      },
   },
});

function AuthWrapper(props) {
   const navigate = useNavigate();

   return (
      <ThemeProvider theme={globalTheme}>
         <CssBaseline />
         <Debug />

         <Box
            sx={{
               px: 8,
               py: 4,
               maxWidth: props.maxWidth,
               margin: "0 auto",
               overflowY: "auto",
            }}
            className='pdBkgnd'
         >
            <Grid container sx={{ mb: 3 }} alignItems='space-between'>
               <Grid item xs={3}>
                  <Stack direction='row' spacing={6} alignItems='center'>
                     <Button onClick={() => navigate("/")} sx={{ ml: -1 }}>
                        <img src={logo} width='100' />
                     </Button>
                  </Stack>
               </Grid>
               <Grid item xs={9} sx={{ textAlign: "right" }}>
                  <Stack spacing={2} direction='row' justifyContent='flex-end'>
                     <SignedIn>
                        <Button
                           variant='outlined'
                           onClick={() => navigate("/admin")}
                        >
                           Events Dashboard
                        </Button>
                        {/* <SignOutButton>
                           <Button variant='outlined'>Sign Out</Button>
                        </SignOutButton> */}
                     </SignedIn>

                     <SignedOut>
                        <SignInButton>
                           <Button variant='outlined'>Sign In</Button>
                        </SignInButton>
                     </SignedOut>

                     <UserButton />
                  </Stack>
               </Grid>
            </Grid>

            {props.children}
         </Box>
      </ThemeProvider>
   );
}

export default AuthWrapper;
