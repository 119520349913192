import React from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";

import { useTheme } from "@mui/material/styles";

export default function RecentBidderList({
   commitBidder,
   recentBidders,
   bidders,
}) {
   const theme = useTheme();

   const handleCommitBidder = (bidderNum) => {
      commitBidder(bidderNum);
   };

   return (
      <>
         {recentBidders && bidders && (
            <Box
               sx={{
                  overflow: "auto",
                  height: 345,
                  boxSizing: "border-box",
                  width: "100%",
               }}
            >
               {recentBidders.map((bidder) => {
                  return (
                     <>
                        {bidders[bidder.bidderNum] && (
                           <>
                              <Box
                                 sx={{
                                    width: "100%",
                                    p: 1,
                                    cursor: "pointer",
                                    borderBottom: "1px solid #333",
                                    "&:hover": {
                                       backgroundColor:
                                          theme.palette.primary.main,
                                    },
                                    boxSizing: "border-box",
                                    fontSize: ".8em",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    WebkitMaskImage:
                                       "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 90%, rgba(0,0,0,0) 100%)",
                                    maskImage:
                                       "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 90%, rgba(0,0,0,0) 100%)",
                                    maskSize: "cover",
                                 }}
                                 id={bidder.bidderNum}
                                 onClick={() =>
                                    handleCommitBidder(bidder.bidderNum)
                                 }
                              >
                                 <Stack
                                    direction='row'
                                    sx={{ width: "100%", position: "relative" }}
                                    spacing={2}
                                    alignItems='center'
                                    justifyContent='center'
                                 >
                                    <b>{bidder.bidderNum}</b>
                                    <Box
                                       sx={{
                                          width: "100%",
                                          height: 40,
                                          position: "relative",
                                       }}
                                    >
                                       <Box
                                          sx={{
                                             width: "100%",
                                             position: "absolute",
                                             top: "50%",
                                             left: 0,
                                             overflow: "hidden",
                                             transform: "translateY(-50%)",
                                          }}
                                       >
                                          {bidders[bidder.bidderNum].name}
                                          {bidders[bidder.bidderNum]
                                             .description && (
                                             <>
                                                <br />
                                                <i>
                                                   {
                                                      bidders[bidder.bidderNum]
                                                         .description
                                                   }
                                                </i>
                                             </>
                                          )}
                                       </Box>
                                    </Box>
                                 </Stack>
                                 {/* <Grid container spacing={2}>
                              <Grid item xs={1}>
                                 <b style={{ marginRight: 10 }}>
                                    {bidder.bidderNum}
                                 </b>
                              </Grid>
                              <Grid item xs={8}>
                                 {bidders[bidder.bidderNum].name}
                              </Grid>
                              <Grid
                                 item
                                 xs={3}
                                 sx={{
                                    textAlign: "right",
                                    pr: 2,
                                    fontStyle: "italic",
                                    color: "#666",
                                 }}
                              >
                                 {new Date(
                                    bidder.updatedAt
                                 ).toLocaleTimeString()}
                              </Grid>
                           </Grid> */}
                              </Box>
                           </>
                        )}
                     </>
                  );
               })}
            </Box>
         )}
      </>
   );
}
