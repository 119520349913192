import React, { useState, useEffect } from "react";

import axios from "axios";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import Papa from "papaparse";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function Import(props) {
   const [importData, setImportData] = useState([]);
   const [columns, setColumns] = useState([]);
   const [rows, setRows] = useState([]);
   const [firstRowHeader, setFirstRowHeader] = useState(true);
   const [importFormatted, setImportFormatted] = useState();

   const [selectedColumns, setSelectedColumns] = useState({
      bidderNum: "",
      name: "",
      description: "",
   });

   const handleSelectChange = (event) => {
      setSelectedColumns({
         ...selectedColumns,
         [event.target.value]: event.target.name,
      });
   };

   const handleFirstRowHeader = (event) => {
      setFirstRowHeader(event.target.checked);
   };

   const collectImport = () => {
      if (selectedColumns.bidderNum && selectedColumns.name) {
         let parsed = rows.map((row) => {
            return {
               eventId: props.eventId,
               bidderNum: row[selectedColumns.bidderNum],
               name: row[selectedColumns.name],
               description: row[selectedColumns.description],
            };
         });

         setImportFormatted(parsed);
      } else {
         setImportFormatted();
         alert("Please select all required columns");
      }
   };

   // EFFECTS

   useEffect(() => {
      if (importData.length > 0) {
         let formatted = [...importData];

         let headerData = [
            { field: "biddernum", name: "Bidder Number" },
            { field: "name", name: "Name" },
            { field: "description", name: "Description" },
         ];

         if (firstRowHeader) {
            headerData = formatted[0].map((header, i) => {
               return {
                  field: header
                     ? header.toLowerCase().replace(/[^a-z0-9]/gi, "")
                     : "column" + i,
                  name: header ? header : "Column " + i,
               };
            });
            formatted.shift();
         } else {
            headerData = formatted[0].map((header, i) => {
               return {
                  field: "column" + i,
                  name: "Column " + i,
               };
            });
         }

         setColumns(headerData);

         let rowData = formatted.map((row, i) => {
            let rowMapped = { id: i };
            let mappedField = row.map((column, o) => {
               rowMapped = {
                  ...rowMapped,
                  [headerData[o] ? headerData[o].field : "column" + o]: column,
               };
            });
            return rowMapped;
         });
         setRows(rowData);
      }
   }, [importData, firstRowHeader]);

   useEffect(() => {
      {
         /* console.log(columns); */
      }
   }, [columns]);

   useEffect(() => {
      {
         /* console.log(rows); */
      }
   }, [rows]);

   useEffect(() => {
      {
         /* console.log(selectedColumns); */
      }
   }, [selectedColumns]);

   useEffect(() => {
      axios
         .post(
            SERVER_URL + "/api/importData",
            JSON.stringify({ data: importFormatted }),
            {
               headers: {
                  "Content-Type": "application/json",
               },
            }
         )
         .then(function (response) {
            if (response.data.success) {
               props.close();
            }
            // console.log(response);
         });
   }, [importFormatted]);

   return (
      <Box
         sx={{
            width: "100%",
            maxWidth: 1500,
            mx: "auto",
            p: 3,
            boxSizing: "border-box",
         }}
      >
         <input
            type='file'
            accept='.csv,.xlsx,.xls'
            onChange={(e) => {
               const files = e.target.files;
               {
                  /* console.log(files); */
               }
               if (files) {
                  {
                     /* console.log(files[0]); */
                  }
                  Papa.parse(files[0], {
                     complete: function (results) {
                        setImportData(results.data);
                     },
                  });
               }
            }}
         />

         <FormControlLabel
            label='Use First Row as Header'
            control={
               <Checkbox
                  checked={firstRowHeader}
                  onChange={handleFirstRowHeader}
                  inputProps={{ "aria-label": "controlled" }}
               />
            }
         />

         <Button
            variant={
               selectedColumns.bidderNum && selectedColumns.name
                  ? "contained"
                  : "disabled"
            }
            color='success'
            sx={{ ml: 2 }}
            onClick={collectImport}
         >
            Import
         </Button>

         <TableContainer sx={{ mt: 3, maxHeight: 500 }}>
            <Table
               sx={{
                  minWidth: 650,
               }}
               stickyHeader
               size='small'
               aria-label='a dense table'
            >
               <TableHead>
                  <TableRow>
                     {columns.map((col, i) => (
                        <TableCell sx={{ width: 100 / columns.length + "%" }}>
                           <FormControl fullWidth>
                              <InputLabel>Select Column</InputLabel>
                              <Select
                                 name={col.field}
                                 value={
                                    col.field === selectedColumns.bidderNum
                                       ? "bidderNum"
                                       : col.field === selectedColumns.name
                                       ? "name"
                                       : col.field ===
                                         selectedColumns.description
                                       ? "description"
                                       : ""
                                 }
                                 label='Import Column'
                                 onChange={handleSelectChange}
                              >
                                 <MenuItem value={"bidderNum"}>
                                    Bidder Number
                                 </MenuItem>
                                 <MenuItem value={"name"}>Name</MenuItem>
                                 <MenuItem value={"description"}>
                                    Description
                                 </MenuItem>
                              </Select>
                           </FormControl>

                           <Box
                              sx={{
                                 backgroundColor: "#333",
                                 color: "#fff",
                                 textAlign: "center",
                                 p: 1,
                                 mt: 2,
                                 fontWeight: "bold",
                              }}
                           >
                              {col.field ? col.field : "Column " + (i + 1)}
                           </Box>
                        </TableCell>
                     ))}
                  </TableRow>
               </TableHead>
               <TableBody>
                  {rows.map((row) => (
                     <TableRow
                        key={"row" + row.id}
                        sx={{
                           "&:last-child td, &:last-child th": { border: 0 },
                        }}
                     >
                        {columns.map((col, i) => (
                           <TableCell>{row[col.field]}</TableCell>
                        ))}
                     </TableRow>
                  ))}
               </TableBody>
            </Table>
         </TableContainer>
      </Box>
   );
}

export default Import;
