export const language = {
   alignItems: "Horizontal Align",
   justifyContent: "Vertical Align",
   color: "Color",
   backgroundColor: "Background Color",
   borderRadius: "Radius",
   fontFamily: "Font Family",
   fontSize: "Font Size",
   fontWeight: "Font Weight",
   fontStyle: "Font Style",
   height: "Height",
   image: "Image",
   lockAspectRatio: "Lock Aspect Ratio?",
   textAlign: "Text Align",
   width: "Width",
   p: "Padding",
   x: "Left",
   y: "Top",
   gridGlobal: "Grid",
   aspectRatioGlobal: "Lock Aspect Ratio?",
};
