import React, { useState, useContext } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

import { useTheme } from "@mui/material/styles";

export default function BidderList(props) {
   const theme = useTheme();

   const [bidderEditInfo, setBidderEditInfo] = useState();

   // MODAL

   const [open, setOpen] = useState(false);

   const handleClose = () => {
      setOpen(false);
   };

   const handleKeyPress = (e) => {
      if (e.key === "Enter") {
         props.updateBidderInfo(bidderEditInfo);
         setOpen(false);
      }
   };

   const modalStyle = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 700,
      bgcolor: "#333",
      outline: 0,
      border: "2px solid #000",
      boxShadow: 24,
      p: 8,
      boxSizing: "border-box",
   };

   const editBidder = (bidder) => {
      // console.log(bidder);

      setBidderEditInfo({
         id: bidder.id,
         eventId: bidder.eventId,
         bidderNum: bidder.bidderNum,
         name: bidder.name,
         description: bidder.description,
      });
      setOpen(true);
   };

   const updateBidderName = (e) => {
      setBidderEditInfo({
         id: e.target.getAttribute("id"),
         eventId: e.target.getAttribute("eventId"),
         bidderNum: e.target.getAttribute("bidderNum"),
         description: e.target.getAttribute("description"),
         name: e.target.value,
      });
   };

   const updateBidderDescription = (e) => {
      setBidderEditInfo({
         id: e.target.getAttribute("id"),
         eventId: e.target.getAttribute("eventId"),
         bidderNum: e.target.getAttribute("bidderNum"),
         name: e.target.getAttribute("name"),
         description: e.target.value,
      });
   };

   const saveBidderInfo = () => {
      props.updateBidderInfo(bidderEditInfo);
      setOpen(false);
   };

   return (
      <>
         {props.bidders && (
            <Box
               sx={{
                  overflow: "auto",
                  height: props.height,
                  boxSizing: "border-box",
                  width: "100%",
               }}
            >
               {Object.keys(props.bidders).map((key) => {
                  return (
                     <>
                        <Box
                           sx={{
                              p: 2,
                              cursor: "pointer",
                              borderBottom: "1px solid #333",
                              "&:hover": {
                                 backgroundColor: theme.palette.primary.main,
                              },
                              boxSizing: "border-box",
                              fontSize: "1.2em",
                           }}
                           onClick={() => editBidder(props.bidders[key])}
                        >
                           <Stack
                              direction='row'
                              sx={{ width: "100%" }}
                              spacing={2}
                              alignItems='center'
                           >
                              <b
                                 style={{
                                    marginRight: 10,
                                    pointerEvents: "none",
                                 }}
                              >
                                 {props.bidders[key].bidderNum}
                              </b>
                              <Box sx={{ width: "100%" }}>
                                 {props.bidders[key].name}
                                 <br />
                                 <i>{props.bidders[key].description}</i>
                              </Box>

                              <Box
                                 sx={{
                                    minWidth: 200,
                                    float: "right",
                                    color: "#fff",
                                    fontWeight: "bold",
                                    pointerEvents: "none",
                                    textAlign: "right",
                                 }}
                              >
                                 EDIT
                              </Box>
                           </Stack>
                        </Box>
                     </>
                  );
               })}
            </Box>
         )}

         <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='modal-title'
            aria-describedby='modal-upload'
         >
            <Box sx={modalStyle}>
               <Typography
                  id='modal-title'
                  variant='h6'
                  component='h2'
                  sx={{ mb: 2 }}
               >
                  Edit Bidder Info
               </Typography>
               {bidderEditInfo && (
                  <Grid
                     container
                     direction='row'
                     justifyContent='center'
                     alignItems='center'
                     spacing={2}
                  >
                     <Grid item xs={2} sx={{ fontWeight: "bold" }}>
                        {bidderEditInfo.bidderNum}
                     </Grid>
                     <Grid item xs={9}>
                        <TextField
                           fullWidth
                           label='Name'
                           id={bidderEditInfo.id}
                           name='name'
                           inputProps={{
                              eventId: bidderEditInfo.eventId,
                              bidderNum: bidderEditInfo.bidderNum,
                              description: bidderEditInfo.description,
                              style: { fontSize: "1.4em" },
                           }}
                           value={bidderEditInfo.name}
                           onChange={updateBidderName}
                           onKeyPress={handleKeyPress}
                           autoFocus={true}
                           sx={{ mb: 3 }}
                        />
                        <TextField
                           fullWidth
                           label='Description'
                           id={bidderEditInfo.id}
                           name='description'
                           inputProps={{
                              eventId: bidderEditInfo.eventId,
                              bidderNum: bidderEditInfo.bidderNum,
                              name: bidderEditInfo.name,
                              style: { fontSize: "1.4em" },
                           }}
                           value={bidderEditInfo.description}
                           onChange={updateBidderDescription}
                           onKeyPress={handleKeyPress}
                        />
                     </Grid>
                     <Grid item xs={1}>
                        <Button variant='outlined' onClick={saveBidderInfo}>
                           SAVE
                        </Button>
                     </Grid>
                  </Grid>
               )}
            </Box>
         </Modal>
      </>
   );
}
