import React, { useState } from "react";

import { SocketContext } from "../../context/socket";

import {
   BrowserRouter as Router,
   Routes,
   Route,
   useNavigate,
} from "react-router-dom";

import Viewer from "./Viewer";
import ViewerL3 from "./ViewerL3";
import ViewerThanks from "./ViewerThanks";

export default function RoutesAll() {
   const navigate = useNavigate();

   return (
      <Routes>
         <Route path='/:eventId' element={<Viewer />} />
         <Route path='/l3/:eventId' element={<ViewerL3 />} />
         <Route path='/thanks/:eventId' element={<ViewerThanks />} />
      </Routes>
   );
}
