import Box from "@mui/material/Box";
import { useAuth } from "@clerk/clerk-react";

export default function Debug() {
   const { userId } = useAuth();

   return (
      <>
         <Box
            sx={{
               position: "absolute",
               bottom: 0,
               left: 0,
               width: "100%",
               p: 4,
               backgroundColor: "rgba(255,0,0,.2)",
               opacity: 0.5,
               display: "none",
            }}
         >
            <b>DEBUG</b>
            <br />
            {userId}
         </Box>
      </>
   );
}
