import React from "react";
import { motion } from "framer-motion";

import Box from "@mui/material/Box";
import RndContainer from "./RndContainer";

// CONTAINER FOR RND AND BOX SWAP IN LAYOUT
export default function Container({
   children,
   edit,
   name,
   data,
   zIndex,
   grid,
   lockAspectRatio,
   previewScale,
   handleUpdate,
   handleAdjust,
   handleActiveElement,
}) {
   if (edit) {
      return (
         <RndContainer
            key={name}
            name={name}
            as={motion.div}
            data={data}
            zIndex={zIndex}
            grid={grid}
            lockAspectRatio={lockAspectRatio}
            callback={handleUpdate}
            handleAdjust={handleAdjust}
            handleActiveElement={handleActiveElement}
            minSize={{ width: 100, height: 50 }}
            scale={previewScale}
         >
            {children}
         </RndContainer>
      );
   } else {
      return (
         <>
            <Box
               key={name}
               as={motion.div}
               initial={{ ...data.position }}
               animate={{ ...data.position }}
               sx={{
                  position: "absolute",
                  boxSizing: "border-box",
                  padding: 0,
                  margin: 0,
                  ...data.parameters,
               }}
            >
               {children}
            </Box>
         </>
      );
   }
}
