import { useContext, useState } from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";

import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import VisibilityIcon from "@mui/icons-material/Visibility";

import InputSlider from "../controls/InputSlider";
import Checkbox from "@mui/material/Checkbox";
import ControlWrapper from "../controls/ControlWrapper";

import UploadButton from "../components/UploadButton";

import { SocketContext } from "../context/socket";
import { StylesContext } from "../context/editorStyles";

const drawerWidth = 240;

export default function EditorSidebar({
   children,
   eventId,
   viewerLayout,
   activeSlideOverride,
}) {
   const {
      layout,
      setLayout,
      layoutFields,
      activeElement,
      setActiveElement,
      grid,
      setGrid,
      lockAspectRatio,
      setLockAspectRatio,
      lastUpdate,
   } = useContext(StylesContext);

   const navigate = useNavigate();
   const socket = useContext(SocketContext);

   const saveLayout = () => {
      socket.emit("updateLayout", layout.styles, eventId);
      setActiveElement();
   };

   const handleLockAspectRatio = () => {
      setLockAspectRatio(!lockAspectRatio);
   };

   const handleActiveElement = (style) => {
      setActiveElement(findStyle(style, viewerLayout));
   };

   const handleResponse = (response) => {
      console.log(response);
   };

   const findStyle = (name, layoutType) => {
      return _.find(layout.styles, function (style) {
         if (style.name === name && style.type === layoutType) {
            return true;
         }
      });
   };

   const [debounceUpdate] = useState(() =>
      _.debounce(function (updateData) {
         // UPDATE DB
         //socket.emit(updateData.emit, updateData.data);
      }, 500)
   );

   const handleControlUpdate = (data) => {
      setLayout((prevState) => {
         let newState = { ...prevState };
         let styles = newState.styles;
         var updateIndex = _.findIndex(styles, (style) => style.id === data.id);

         if (data.column === "text") {
            newState["styles"][updateIndex][data.column] = data.value;
         } else {
            newState["styles"][updateIndex][data.column] = {
               ...newState["styles"][updateIndex][data.column],
               ...data.updates,
            };
         }

         debounceUpdate({
            emit: "updateStyle",
            data: newState["styles"][updateIndex],
         });

         return newState;
      });
   };

   return (
      <Box sx={{ display: "flex" }}>
         <CssBaseline />
         <Drawer
            sx={{
               width: drawerWidth,
               flexShrink: 0,
               "& .MuiDrawer-paper": {
                  width: drawerWidth,
                  boxSizing: "border-box",
               },
            }}
            variant='permanent'
            anchor='left'
         >
            <List className='sidebarEditor'>
               <ListItem key={"gridGlobal"}>
                  <ListItemText
                     primary={
                        <Button
                           color='error'
                           variant='outlined'
                           size='small'
                           onClick={() =>
                              navigate("/admin/dashboard/" + eventId)
                           }
                        >
                           Back to Producer
                        </Button>
                     }
                  />
               </ListItem>
               <ListItem key={"gridGlobal2"}>
                  <ListItemText
                     primary={
                        <Box
                           sx={{
                              backgroundColor: "#222",
                              px: 1,
                              py: 0.5,
                              fontWeight: "bold",
                           }}
                        >
                           GRID
                        </Box>
                     }
                     secondary={
                        <Box
                           sx={{
                              px: 1,
                           }}
                        >
                           <InputSlider
                              key='aspectRatioGlobalInput'
                              value={grid}
                              inputOptions={{ step: 1, min: 1, max: 200 }}
                              callback={(value) => setGrid(value)}
                           />
                        </Box>
                     }
                  />
               </ListItem>

               <ListItem key={"aspectRatioGlobal"}>
                  <ListItemText
                     primary={
                        <Box
                           sx={{
                              backgroundColor: "#222",
                              px: 1,
                              py: 0.5,
                              fontWeight: "bold",
                           }}
                        >
                           ASPECT RATIO
                        </Box>
                     }
                     secondary={
                        <Box
                           sx={{
                              px: 1,
                              fontWeight: "bold",
                           }}
                        >
                           Lock Aspect Ratio
                           <Checkbox
                              checked={lockAspectRatio}
                              onChange={handleLockAspectRatio}
                           />
                        </Box>
                     }
                  />
               </ListItem>
               <ListItem>
                  <ListItemText
                     primary={
                        <Box
                           sx={{
                              backgroundColor: "#222",
                              px: 1,
                              py: 0.5,
                              fontWeight: "bold",
                           }}
                        >
                           IMAGE
                        </Box>
                     }
                     secondary={
                        <Box
                           sx={{
                              py: 0.5,
                              fontWeight: "bold",
                           }}
                        >
                           <UploadButton
                              eventId={eventId}
                              location={viewerLayout}
                              handleResponse={handleResponse}
                           />
                        </Box>
                     }
                  />
               </ListItem>

               <Divider />

               <ListItem>
                  <ListItemText>ELEMENTS</ListItemText>
               </ListItem>

               {layout && layoutFields && (
                  <>
                     {layoutFields.map((active) => (
                        <ListItem
                           key={active}
                           sx={{
                              backgroundColor:
                                 activeElement && active === activeElement.name
                                    ? "#450001"
                                    : "",
                           }}
                           disablePadding
                        >
                           <ListItemButton
                              onClick={() => handleActiveElement(active)}
                           >
                              <ListItemIcon>
                                 <VisibilityIcon />
                              </ListItemIcon>
                              <ListItemText primary={active} />
                           </ListItemButton>
                        </ListItem>
                     ))}
                  </>
               )}
               <Divider />

               {activeElement && (
                  <ListItem sx={{ py: 3 }}>
                     <Button
                        color='primary'
                        variant='contained'
                        size='small'
                        onClick={() => navigate("/admin/dashboard/" + eventId)}
                        sx={{ float: "right" }}
                     >
                        CANCEL
                     </Button>
                     <Button
                        color='error'
                        variant='contained'
                        size='small'
                        onClick={saveLayout}
                        sx={{ float: "right", ml: 2 }}
                     >
                        SAVE
                     </Button>
                  </ListItem>
               )}

               {!activeElement && (
                  <ListItem
                     sx={{
                        fontStyle: "italic",
                        color: "#666",
                        textAlign: "center",
                     }}
                  >
                     Click element to edit
                  </ListItem>
               )}

               {activeElement && (
                  <>
                     <Typography
                        variant='h4'
                        component='div'
                        sx={{
                           textTransform: "uppercase",
                           p: 2,
                           fontSize: "18px !important",
                           fontWeight: "bold",
                           backgroundColor: "#000",
                        }}
                     >
                        {activeElement.name}
                     </Typography>

                     <ControlWrapper
                        eventId={eventId}
                        data={activeElement}
                        grid={grid}
                        saveLayout={saveLayout}
                        handleControlUpdate={handleControlUpdate}
                        lastUpdate={lastUpdate}
                     />
                  </>
               )}
            </List>
         </Drawer>
         <Box
            component='main'
            sx={{
               flexGrow: 1,
               width: window.innerWidth - 240,
               overflow: "hidden",
            }}
         >
            {children}
         </Box>
      </Box>
   );
}
