import * as React from "react";

import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputSlider from "./InputSlider";
import SelectMenus from "./SelectMenus";

import Divider from "@mui/material/Divider";

import ColorPicker from "../components/ColorPicker";
import RowWrapper from "../components/SidebarWrapper";

const inputOptions = {
   x: { step: 1, min: 0, max: 1920 },
   y: { step: 1, min: 0, max: 1080 },
   p: { step: 0.1, min: 0, max: 40 },
   width: { step: 1, min: 0, max: 1920 },
   height: { step: 1, min: 0, max: 1080 },
   fontSize: { step: 0.1, min: 8, max: 300 },
   borderRadius: { step: 1, min: 0, max: 200 },
};

const menuOptions = {
   fontStyle: [
      { label: "Normal", value: "unset" },
      { label: "Italic", value: "italic" },
   ],
   fontFamily: [
      { label: "Arial", value: "Arial" },
      { label: "Courier", value: "Courier" },
      { label: "Helvetica", value: "Helvetica" },
      { label: "Lucida Grande", value: "Lucida Grande" },
      { label: "Noto Sans", value: "Noto Sans" },
      { label: "Tahoma", value: "Tahoma" },
      { label: "P22", value: "P22" },
   ],
   fontWeight: [
      { label: "Light", value: "100" },
      { label: "Normal", value: "500" },
      { label: "Bold", value: "700" },
      { label: "ExtraBold", value: "900" },
   ],
   textAlign: [
      { label: "Left", value: "left" },
      { label: "Center", value: "center" },
      { label: "Right", value: "right" },
   ],
   alignItems: [
      { label: "Left", value: "flex-start" },
      { label: "Center", value: "center" },
      { label: "Right", value: "flex-end" },
   ],
   justifyContent: [
      { label: "Top", value: "flex-start" },
      { label: "Center", value: "center" },
      { label: "Bottom", value: "flex-end" },
   ],
};

export default function ControlWrapper({
   eventId,
   data,
   grid,
   saveLayout,
   lastUpdate,
   handleControlUpdate,
}) {
   const blurOnReturn = (e) => {
      if (e.key === "Enter") {
         e.target.blur();
      }
   };

   const controlRender = (id, name, key, value) => {
      switch (key) {
         // CUSTOM TEXT
         case "text":
            return (
               <RowWrapper label={key}>
                  <TextField
                     key={id + "_" + key}
                     defaultValue={value}
                     fullWidth
                     sx={{ backgroundColor: "#300" }}
                     onKeyPress={blurOnReturn}
                     onBlur={(e) =>
                        handleControlUpdate({
                           id: id,
                           column: "text",
                           value: e.target.value,
                        })
                     }
                  />
               </RowWrapper>
            );
            break;
         //POSITION CONTROLS
         case "x":
         case "y":
            return (
               <RowWrapper label={key}>
                  <InputSlider
                     key={id + "_" + key}
                     value={value}
                     inputOptions={{ ...inputOptions[key], step: grid }}
                     callback={(value) =>
                        handleControlUpdate({
                           id: id,
                           column: "position",
                           updates: { [key]: value },
                        })
                     }
                  />
               </RowWrapper>
            );
            break;
         case "width":
         case "height":
         case "p":
            return (
               <RowWrapper label={key}>
                  <InputSlider
                     key={id + "_" + key}
                     value={value}
                     inputOptions={inputOptions[key]}
                     callback={(value) =>
                        handleControlUpdate({
                           id: id,
                           column: "position",
                           updates: { [key]: value },
                        })
                     }
                  />
               </RowWrapper>
            );
            break;
         case "alignItems":
         case "justifyContent":
            return (
               <RowWrapper label={key}>
                  <SelectMenus
                     key={id + "_" + key}
                     value={value}
                     menuOptions={menuOptions[key]}
                     callback={(value) =>
                        handleControlUpdate({
                           id: id,
                           column: "position",
                           updates: { [key]: value },
                        })
                     }
                  />
               </RowWrapper>
            );

         // PARAMETERS CONTROLS
         case "backgroundColor":
         case "color":
            return (
               <RowWrapper label={key}>
                  <ColorPicker
                     width={40}
                     key={id + "_" + key}
                     color={value}
                     callback={(value) =>
                        handleControlUpdate({
                           id: id,
                           column: "parameters",
                           updates: { [key]: value },
                        })
                     }
                  />
               </RowWrapper>
            );
            break;
         case "fontSize":
            return (
               <RowWrapper label={key}>
                  <InputSlider
                     key={id + "_" + key}
                     value={value}
                     inputOptions={inputOptions[key]}
                     callback={(value) =>
                        handleControlUpdate({
                           id: id,
                           column: "parameters",
                           updates: { [key]: value },
                        })
                     }
                  />
               </RowWrapper>
            );
            break;
         case "borderRadius":
            return (
               <RowWrapper label={key}>
                  <InputSlider
                     key={id + "_" + key}
                     value={value}
                     inputOptions={inputOptions[key]}
                     callback={(value) =>
                        handleControlUpdate({
                           id: id,
                           column: "parameters",
                           updates: { [key]: value },
                        })
                     }
                  />
               </RowWrapper>
            );
            break;
         case "fontWeight":
         case "fontFamily":
         case "fontStyle":
         case "textAlign":
            return (
               <RowWrapper label={key}>
                  <SelectMenus
                     key={id + "_" + key}
                     value={value}
                     menuOptions={menuOptions[key]}
                     callback={(value) =>
                        handleControlUpdate({
                           id: id,
                           column: "parameters",
                           updates: { [key]: value },
                        })
                     }
                  />
               </RowWrapper>
            );
         default:
            break;
      }
   };

   return (
      <>
         {data && (
            <>
               <Divider />

               {data.name === "thankyou" && (
                  <>
                     <Typography
                        variant='h5'
                        component='div'
                        sx={{
                           textTransform: "uppercase",
                           p: 2,
                           backgroundColor: "#444",
                        }}
                     >
                        Custom Text
                     </Typography>

                     {controlRender(data.id, data.name, "text", data.text)}
                  </>
               )}
               <Typography
                  variant='h5'
                  component='div'
                  sx={{
                     textTransform: "uppercase",
                     p: 2,
                     backgroundColor: "#444",
                  }}
               >
                  Parameters
               </Typography>

               {Object.entries(data.parameters).map((parameter) =>
                  controlRender(data.id, data.name, parameter[0], parameter[1])
               )}

               <Typography
                  variant='h5'
                  component='div'
                  sx={{
                     textTransform: "uppercase",
                     p: 2,
                     backgroundColor: "#444",
                  }}
               >
                  Position
               </Typography>

               {Object.entries(data.position).map((position) =>
                  controlRender(data.id, data.name, position[0], position[1])
               )}
            </>
         )}
      </>
   );
}
