import React, { useState } from "react";

import axios from "axios";

import Uploader from "./Uploader";

// MUI
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import UploadImageIcon from "@mui/icons-material/AddPhotoAlternate";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function UploadImage({ eventId, location, handleResponse }) {
   const [open, setOpen] = useState(false);

   const handleOpen = (name) => {
      setOpen(true);
   };

   const handleClose = () => {
      setOpen(false);
   };

   const modalStyle = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 600,
      height: "auto",
      bgcolor: "#333",
      outline: 0,
      border: "4px solid #111",
      borderRadius: 5,
      boxShadow: 24,
      p: 4,
      boxSizing: "border-box",
   };

   return (
      <>
         <Button
            color='warning'
            fullWidth
            size='small'
            startIcon={<UploadImageIcon />}
            onClick={() => handleOpen(location)}
         >
            Upload Image
         </Button>

         <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='modal-title'
            aria-describedby='modal-upload'
         >
            <Box sx={modalStyle}>
               <Typography id='modal-title' variant='h6' component='h2'>
                  Upload Image - {location}
               </Typography>
               <Typography id='modal-upload' sx={{ pt: 2 }}>
                  <Uploader
                     eventId={eventId}
                     location={location}
                     handleResponse={handleClose}
                  />
               </Typography>
            </Box>
         </Modal>
      </>
   );
}
