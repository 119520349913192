import React, { useState, useContext, useEffect } from "react";

import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import CopyIcon from "@mui/icons-material/ContentCopy";

export default function Urls({ eventId }) {
   const [open, setOpen] = useState(false);
   const [message, setMessage] = useState();

   // COPY URL
   const getHost = () => {
      return window.location.host;
   };

   const copyToClipboard = (text) => {
      if (navigator.clipboard) {
         navigator.clipboard.writeText(text);
      } else {
         alert(text);
      }
   };

   const handleCopy = (e) => {
      const text = e.target.value;
      copyToClipboard(text);
      setMessage("Copied: " + text);
      setOpen(true);
   };

   const handleClick = () => {
      setOpen(true);
   };

   const handleClose = (event, reason) => {
      if (reason === "clickaway") {
         return;
      }

      setOpen(false);
   };

   const action = (
      <>
         <IconButton
            size='small'
            aria-label='close'
            color='inherit'
            onClick={handleClose}
         >
            <CloseIcon fontSize='small' />
         </IconButton>
      </>
   );

   return (
      <>
         <Box sx={{ pl: 4 }}>
            <Stack
               direction='row'
               alignItems='center'
               justifyContent='flex-start'
               spacing={2}
               sx={{ width: "100%" }}
            >
               {/* <Button
                  value={getHost() + "/client/" + eventId}
                  onClick={handleCopy}
                  variant='outlined'
                  sx={{ whiteSpace: "nowrap" }}
                  startIcon={<CopyIcon />}
               >
                  CLIENT URL
               </Button> */}
               <Button
                  value={getHost() + "/viewer/" + eventId}
                  onClick={handleCopy}
                  variant='outlined'
                  sx={{ whiteSpace: "nowrap" }}
                  startIcon={<CopyIcon />}
               >
                  VIEWER URL
               </Button>
               <Button
                  value={getHost() + "/viewer/l3/" + eventId}
                  onClick={handleCopy}
                  variant='outlined'
                  sx={{ whiteSpace: "nowrap" }}
                  startIcon={<CopyIcon />}
               >
                  LOWER THIRD URL
               </Button>
            </Stack>
         </Box>

         <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={open}
            autoHideDuration={2000}
            onClose={handleClose}
            action={action}
         >
            <Alert onClose={handleClose} severity='warning'>
               {message}
            </Alert>
         </Snackbar>
      </>
   );
}
