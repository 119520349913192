import React, { useContext, useEffect } from "react";
import { useParams } from "react-router";

import { SocketContext } from "../../context/socket";
import { StylesContextProvider } from "../../context/editorStyles";

import Viewport from "../../components/Viewport";

// COMPONENT

export default function ViewerL3() {
   const socket = useContext(SocketContext);
   const { eventId } = useParams();

   useEffect(() => {
      socket.emit("joinRoom", eventId);
      document.body.classList.add("noScroll");
   }, []);

   useEffect(() => {
      socket.on("refreshViewer", () => {
         window.location.reload(false);
      });
   }, [socket]);

   return (
      <StylesContextProvider>
         <Viewport edit={false} previewScale={1} viewerLayout={"lowerthird"} />
      </StylesContextProvider>
   );
}
