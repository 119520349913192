import React, { useState, useContext, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useAuth } from "@clerk/clerk-react";
import useEvents from "../../hooks/useEvents";

import Urls from "../../components/Urls";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";

// ICONS
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import PreviewIcon from "@mui/icons-material/Preview";
import DashboardIcon from "@mui/icons-material/Dashboard";

const modalStyle = {
   position: "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   width: 400,
   height: "auto",
   textAlign: "center",
   bgcolor: "background.paper",
   border: "2px solid #000",
   boxShadow: 24,
   p: 4,
};

function Admin() {
   const { userId, isLoaded, isSignedIn } = useAuth();

   const [events, { getAllEvents, addEvent, deleteEvent, updateEvent }] =
      useEvents();

   const navigate = useNavigate();

   // STATES
   const [eventTitle, setEventTitle] = useState();
   const [eventId, setEventId] = useState();

   // EFFECTS
   useEffect(() => {
      getAllEvents();
   }, [userId]);

   const editEvent = (title, eventid) => {
      setEventTitle(title);
      setEventId(eventid);
      handleModalOpen();
   };

   // FUNCTIONS
   const handleUpdate = (e) => {
      setEventTitle(e.target.value);
   };

   const handleKeyPress = (e) => {
      if (e.key === "Enter") {
         if (!eventId) {
            addEvent(eventTitle);
         } else {
            updateEvent(eventTitle, eventId);
         }
         handleModalClose();
      }
   };

   const handleEventSubmit = () => {
      if (!eventId) {
         addEvent(eventTitle);
      } else {
         updateEvent(eventTitle, eventId);
      }
      handleModalClose();
   };

   const handleAddEventOpen = () => {
      setEventTitle();
      setEventId();
      setOpenModal(true);
   };

   // MODAL

   const [openModal, setOpenModal] = useState(false);
   const handleModalOpen = (name) => {
      setOpenModal(true);
   };
   const handleModalClose = () => {
      setOpenModal(false);
   };

   return (
      <>
         <Typography variant='body1' component='div'>
            <Grid container alignItems='center'>
               <Grid item xs={9}>
                  <Typography variant='h4' gutterBottom component='div'>
                     Events
                  </Typography>
               </Grid>
               <Grid item xs={3}>
                  <Button
                     variant='contained'
                     color='primary'
                     onClick={handleAddEventOpen}
                     fullWidth
                  >
                     + ADD EVENT
                  </Button>
               </Grid>
            </Grid>
            {events.length > 0 &&
               events.map((event) => (
                  <Grid
                     container
                     justifyContent='center'
                     alignItems='center'
                     spacing={4}
                     sx={{ mt: 4, borderTop: "1px solid #333" }}
                  >
                     <Grid item xs={3}>
                        <Typography variant='h6' gutterBottom component='div'>
                           {event.name ? event.name : "Unnamed Event"}
                           <Button
                              color='info'
                              sx={{ ml: 2 }}
                              size='small'
                              onClick={() => editEvent(event.name, event.id)}
                              startIcon={<EditIcon />}
                           >
                              EDIT TITLE
                           </Button>
                        </Typography>
                     </Grid>
                     <Grid item xs={8}>
                        <Stack
                           direction='row'
                           alignItems='center'
                           justifyContent='flex-start'
                           spacing={2}
                           sx={{ width: "100%" }}
                        >
                           <Button
                              color='warning'
                              onClick={() =>
                                 navigate("/admin/dashboard/" + event.id)
                              }
                              startIcon={<DashboardIcon />}
                              variant='outlined'
                           >
                              Producer
                           </Button>
                           {/* <Button
                              color='secondary'
                              onClick={() =>
                                 navigate("/admin/client/" + event.id)
                              }
                              startIcon={<DashboardIcon />}
                              variant='outlined'
                           >
                              Client
                           </Button> */}

                           <Urls eventId={event.id} />
                        </Stack>
                     </Grid>
                     <Grid
                        item
                        xs={1}
                        sx={{
                           p: 2,
                        }}
                     >
                        <IconButton
                           aria-label='delete'
                           variant='contained'
                           color='error'
                           size='small'
                           onClick={() => deleteEvent(event.id)}
                        >
                           <DeleteIcon />
                        </IconButton>
                     </Grid>
                  </Grid>
               ))}
         </Typography>

         <Modal
            open={openModal}
            onClose={handleModalClose}
            aria-labelledby='modal-title'
            aria-describedby='modal-upload'
         >
            <Box sx={modalStyle}>
               <Typography id='modal-title' variant='h6' component='h2'>
                  Event Title
               </Typography>
               <Typography id='modal-input' sx={{ pt: 2 }}>
                  <TextField
                     fullWidth
                     key='eventTitle'
                     id='eventTitle'
                     label='Event Title'
                     variant='outlined'
                     value={eventTitle}
                     onKeyPress={handleKeyPress}
                     onChange={handleUpdate}
                  />

                  <Button
                     variant='contained'
                     onClick={handleEventSubmit}
                     sx={{ my: 3 }}
                  >
                     SUBMIT
                  </Button>
               </Typography>
            </Box>
         </Modal>
      </>
   );
}

export default Admin;
