import React, { useEffect, useState, useContext } from "react";
import ReactDOM from "react-dom";

import {
   BrowserRouter as Router,
   Routes,
   Route,
   useNavigate,
} from "react-router-dom";

import Wrapper from "../components/Wrapper";
import Home from "../routes/public/Home";
import Public from "../routes/public/RoutesPublic";
import Forbidden from "../routes/public/Forbidden";
import Private from "../routes/private/RoutesPrivate";
import All from "../routes/all/RoutesAll";
import SocketProvider from "../components/SocketProvider";

// CLERK
import {
   ClerkProvider,
   ClerkLoaded,
   SignedIn,
   SignedOut,
   UserButton,
   useUser,
} from "@clerk/clerk-react";

// SOCKET.IO
import { socket } from "../context/socket";

if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
   throw new Error("Missing Publishable Key");
}
const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

export default function App({ currentSocket }) {
   return (
      <ClerkProvider
         publishableKey={clerkPubKey}
         // navigate={(to) => navigate(to)}
      >
         <ClerkLoaded>
            <SocketProvider currentSocket={currentSocket}>
               <Router>
                  <SignedIn>
                     <Routes>
                        <Route path='/viewer/*' element={<All />} />
                        <Route
                           path='/*'
                           element={
                              <Wrapper>
                                 <Public />
                              </Wrapper>
                           }
                        />
                        <Route path='/admin/*' element={<Private />} />
                     </Routes>
                  </SignedIn>
                  <SignedOut>
                     <Routes>
                        <Route path='/viewer/*' element={<All />} />
                        <Route
                           path='/admin/*'
                           element={
                              <Wrapper>
                                 <Forbidden />
                              </Wrapper>
                           }
                        />
                        <Route
                           path='/*'
                           element={
                              <Wrapper>
                                 <Public />
                              </Wrapper>
                           }
                        />
                     </Routes>
                  </SignedOut>
               </Router>
            </SocketProvider>
         </ClerkLoaded>
      </ClerkProvider>
   );
}
