import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

export default function SelectMenus({ label, value, menuOptions, callback }) {
   const [inputValue, setInputValue] = useState(value);

   useEffect(() => {
      callback(inputValue);
   }, [inputValue]);

   const handleStyleChange = (event) => {
      setInputValue(event.target.value);
   };

   return (
      <Box sx={{ width: "100%" }}>
         <Grid
            container
            alignItems='center'
            justifyContent='center'
            sx={{ p: 0, textAlign: "left", width: "100%", color: "#fff" }}
         >
            {label && (
               <Grid
                  item
                  xs={4}
                  sx={{
                     p: 1,
                     fontWeight: "bold",
                     textTransform: "uppercase",
                     fontSize: 11,
                  }}
               >
                  {label}
               </Grid>
            )}
            <Grid item xs={label ? 8 : 12}>
               {menuOptions && menuOptions.length > 0 && (
                  <Select
                     value={inputValue}
                     onChange={handleStyleChange}
                     sx={{ width: "100%" }}
                     size='small'
                  >
                     {menuOptions.map((row) => (
                        <MenuItem value={row.value}>{row.label}</MenuItem>
                     ))}
                  </Select>
               )}

               {menuOptions.length === 0 && <>Loading...</>}
            </Grid>
         </Grid>
      </Box>
   );
}
