import React, { useState, useContext, useEffect, useRef } from "react";
import { useParams } from "react-router";

import { SocketContext } from "../../context/socket";
import _ from "lodash";

import BidderList from "../../components/BidderListEditor.js";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function Editor() {
   const { eventId } = useParams();
   const socket = useContext(SocketContext);

   const headerRef = useRef();

   const [bidders, setBidders] = useState();
   const [biddersById, setBiddersById] = useState();
   const [eventTitle, setEventTitle] = useState();

   // EFFECTS

   useEffect(() => {
      socket.emit("joinRoom", eventId);

      socket.emit("getBidders", eventId, (payload) => {
         setBidders(_.keyBy(payload, "bidderNum"));
         setBiddersById(_.keyBy(payload, "id"));
      });

      socket.emit("getEvent", eventId, (event) => {
         if (event) {
            setEventTitle(event.name);
         }
      });
   }, []);

   useEffect(() => {
      socket.on("refreshBidderList", () => {
         socket.emit("getBidders", eventId, (payload) => {
            setBidders(_.keyBy(payload, "bidderNum"));
            setBiddersById(_.keyBy(payload, "id"));
         });
      });
   }, [socket]);

   // FUNCTIONS

   return (
      <Box
         sx={{
            width: "100%",
            mx: "auto",
            overflow: "hidden",
            boxSizing: "border-box",
         }}
      >
         <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
            spacing={2}
            ref={headerRef}
         >
            <Grid item xs={12}>
               <Typography variant='h4' gutterBottom component='div'>
                  {eventTitle}
               </Typography>
            </Grid>
         </Grid>

         {headerRef.current && (
            <>
               <Box
                  sx={{
                     overflowY: "auto",
                     height:
                        window.innerHeight -
                        200 -
                        parseInt(
                           window.getComputedStyle(headerRef.current).height
                        ),
                  }}
               >
                  {bidders && (
                     <BidderList
                        key={"bidderList"}
                        bidders={biddersById}
                        editOnly={true}
                     />
                  )}
               </Box>
               <Button
                  size='small'
                  variant='outlined'
                  href={SERVER_URL + "/api/saveDB/" + eventId}
                  sx={{ mt: 2 }}
                  fullWidth
               >
                  DOWNLOAD BIDDER LIST
               </Button>
            </>
         )}
      </Box>
   );
}

export default Editor;
