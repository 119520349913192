import React from "react";

import { StylesContextProvider } from "../../context/editorStyles";

import Viewport from "../../components/Viewport";

// COMPONENT

export default function ViewerThanks() {
   return (
      <StylesContextProvider>
         <Viewport
            edit={false}
            activeSlideOverride={"thanks"}
            previewScale={1}
            viewerLayout={"thanks"}
         />
      </StylesContextProvider>
   );
}
