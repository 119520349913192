import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";

import { SocketContext } from "../context/socket";
import { StylesContext } from "../context/editorStyles";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import Container from "./Container";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const updateNameWidow = (name) => {
   if (name) {
      let nameArray = name.split(" ");
      let lastWord = nameArray.pop();
      let parsedName = nameArray.join(" ") + "&nbsp;" + lastWord;
      return parsedName;
   }
};

const textAlignConverter = {
   "flex-start": "left",
   center: "center",
   "flex-end": "right",
};

export default function Viewport({
   edit,
   activeSlideOverride,
   previewScale,
   viewerLayout,
}) {
   const socket = useContext(SocketContext);
   const { eventId } = useParams();

   const {
      layout,
      setLayout,
      layoutFields,
      setLayoutFields,
      setActiveElement,
      grid,
      lockAspectRatio,
      setLastUpdate,
   } = useContext(StylesContext);

   const [activeSlide, setActiveSlide] = useState();

   // const [debounceUpdate] = useState(() =>
   //    _.debounce(function (updateData) {
   //       // UPDATE DB
   //       //socket.emit(updateData.emit, updateData.data);
   //    }, 500)
   // );

   useEffect(() => {
      socket.emit("joinRoom", eventId);
      socket.emit("getEvent", eventId, (event) => {
         if (event) {
            if (edit) {
               setActiveSlide({
                  data: {
                     id: 1977,
                     name: "Punch Drunk Productions",
                     bidderNum: 107,
                  },
                  type: "bidder",
               });
            } else if (activeSlideOverride) {
               setActiveSlide({
                  type: activeSlideOverride,
                  eventId: eventId,
               });
            } else {
               setActiveSlide(event.activeSlide);
            }

            setLayout({ styles: event.styles, lastUpdate: Date.now() });

            switch (viewerLayout) {
               case "fullscreen":
                  return setLayoutFields(event.layoutFullscreen);
                  break;
               case "lowerthird":
                  return setLayoutFields(event.layoutL3);
                  break;
               case "thanks":
                  return setLayoutFields(["background", "thankyou"]);
                  break;
               default:
                  break;
            }
         }
      });
   }, []);

   useEffect(() => {
      socket.on("refreshStyles", (styles) => {
         if (styles) {
            setLayout({ styles: styles, lastUpdate: Date.now() });
         }
      });

      socket.on("setSlide", (slide) => {
         if (activeSlideOverride) {
            setActiveSlide({ type: activeSlideOverride, eventId: eventId });
         } else {
            setActiveSlide(slide);
         }
      });
   }, [socket]);

   useEffect(() => {
      // console.log(activeSlide);
   }, [activeSlide]);

   useEffect(() => {
      // console.log(layout);
   }, [layout]);

   const handleViewerReload = (eventId) => {
      socket.emit("refreshViewers", eventId);
   };

   const handleUpdate = (data) => {
      setLayout((prevState) => {
         let newState = { ...prevState };
         let styles = newState.styles;
         var updateIndex = _.findIndex(styles, (style) => style.id === data.id);

         newState["styles"][updateIndex][data.column] = {
            ...newState["styles"][updateIndex][data.column],
            ...data.updates,
         };

         // debounceUpdate({
         //    emit: "updateStyle",
         //    data: newState["styles"][updateIndex],
         // });

         setLastUpdate(Date.now());

         // UPDATE DB
         // socket.emit("updateStyle", newState["styles"][updateIndex]);

         return newState;
      });
   };

   const findStyle = (name, layoutType) => {
      return _.find(layout.styles, function (style) {
         if (style.name === name && style.type === layoutType) {
            return true;
         }
      });
   };

   const handleActiveElement = (style) => {
      setActiveElement(style);
   };

   const handleAdjust = (name, adjustment) => {
      let id = findStyle(name, viewerLayout).id;

      let newPosition = findStyle(name, viewerLayout).position;

      switch (adjustment) {
         case "fit":
            newPosition.width = 1920;
            newPosition.height = 1080;
            newPosition.x = 0;
            newPosition.y = 0;

            handleUpdate({
               id: id,
               column: "position",
               updates: { ...newPosition },
            });
            break;
         case "center":
            newPosition.x = 1920 / 2 - newPosition.width / 2;

            handleUpdate({
               id: id,
               column: "position",
               updates: { ...newPosition },
            });
            break;
         default:
      }
   };

   const styleRender = (style) => {
      switch (style.name) {
         case "background":
            return (
               <img
                  key={"background_" + style.image}
                  src={SERVER_URL + "/uploads/" + style.image}
                  width='100%'
               />
            );
            break;
         case "lowerthird":
            return (
               <img
                  key={"lowerthird_" + style.image}
                  src={SERVER_URL + "/uploads/" + style.image}
                  width='100%'
               />
            );
            break;
         case "thankyou":
            return (
               <motion.div
                  style={{ position: "relative" }}
                  initial={{ opacity: 0, left: -50 }}
                  animate={{ opacity: 1, left: 0 }}
                  exit={{ opacity: 0, left: 50 }}
                  transition={{ duration: 0.5 }}
                  key={"thankyou"}
               >
                  {edit && !style.text ? "THANK YOU" : style.text}
               </motion.div>
            );
            break;
         case "name":
            return (
               <motion.div
                  style={{
                     position: "absolute",
                     width: "100%",
                  }}
                  initial={{ opacity: 0, scale: 1.1 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5 }}
                  key={
                     "name_" + activeSlide && activeSlide.data
                        ? activeSlide.data.id
                        : "thanks"
                  }
               >
                  {edit && !activeSlide.data.name ? (
                     "Bidder Name"
                  ) : (
                     <>
                        {activeSlide.type === "thanks" &&
                        viewerLayout === "lowerthird" ? (
                           <Box
                              dangerouslySetInnerHTML={{
                                 __html: "Thank You",
                              }}
                           />
                        ) : (
                           <Box
                              sx={{
                                 p: style.position.p,
                                 transform: activeSlide.data.description
                                    ? "translateY(-20px)"
                                    : "none",
                              }}
                              dangerouslySetInnerHTML={{
                                 __html: updateNameWidow(activeSlide.data.name),
                              }}
                           />
                        )}
                     </>
                  )}
               </motion.div>
            );
            break;
         case "biddernum":
            return (
               <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1 }}
                  key={"biddernum_" + activeSlide.data.id}
               >
                  {edit && !activeSlide.data.bidderNum
                     ? "Bidder 000"
                     : "Bidder " + activeSlide.data.bidderNum}
               </motion.div>
            );
            break;
         case "affiliation":
            return (
               <>
                  {edit && !activeSlide.data.affiliation
                     ? "Affiliation"
                     : activeSlide.data.affiliation}
               </>
            );
            break;
         case "custom1":
            return (
               <>
                  {edit && !activeSlide.data.custom1
                     ? "Custom Data Field 1"
                     : activeSlide.data.custom1}
               </>
            );
            break;
         case "custom2":
            return (
               <>
                  {edit && !activeSlide.data.custom2
                     ? "Custom Data Field 2"
                     : activeSlide.data.custom2}
               </>
            );
            break;
         case "description":
            return (
               <motion.div
                  style={{ position: "absolute" }}
                  initial={{ opacity: 0, scale: 1.1 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5 }}
                  key={
                     "description_" + activeSlide && activeSlide.data
                        ? activeSlide.data.id
                        : "thanks"
                  }
               >
                  {edit && !activeSlide.data.description ? (
                     "Description"
                  ) : (
                     <>
                        {activeSlide.type !== "thanks" && (
                           <Box
                              dangerouslySetInnerHTML={{
                                 __html: updateNameWidow(
                                    activeSlide.data.description
                                 ),
                              }}
                           />
                        )}
                     </>
                  )}
               </motion.div>
            );
            break;
         default:
            break;
      }
   };

   return (
      <>
         <Box
            key='frame'
            sx={{
               width: 1920,
               height: 1080 * previewScale,
               transform: "scale(" + previewScale + ")",
               transformOrigin: "top left",
               position: "relative",
            }}
         >
            {activeSlide && (
               <>
                  <AnimatePresence>
                     {activeSlide.type === "logo" &&
                        viewerLayout !== "lowerthird" && (
                           <motion.div
                              key='LogoLayout'
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              exit={{ opacity: 0, transition: { delay: 0.5 } }}
                              style={{
                                 position: "absolute",
                                 top: 0,
                                 left: 0,
                                 width: 1920,
                                 height: 1080,
                              }}
                           >
                              {layout && layout.styles && layoutFields && (
                                 <Box
                                    className={edit ? "editMode" : "viewMode"}
                                    key={activeSlide}
                                 >
                                    {["background"].map((active) => (
                                       <Container
                                          edit={edit}
                                          name={findStyle(active, "logo").name}
                                          data={findStyle(active, "logo")}
                                          zIndex={
                                             findStyle(active, "logo").zIndex
                                          }
                                          grid={grid}
                                          lockAspectRatio={lockAspectRatio}
                                          previewScale={previewScale}
                                          handleUpdate={handleUpdate}
                                          handleAdjust={handleAdjust}
                                          handleActiveElement={
                                             handleActiveElement
                                          }
                                          key={findStyle(active, "logo").name}
                                       >
                                          <Box
                                             className='container'
                                             sx={{
                                                position: "absolute",
                                                boxSizing: "border-box",
                                                width: "100%",
                                                height: "100%",
                                             }}
                                          >
                                             <Stack
                                                justifyContent={
                                                   findStyle(active, "logo")
                                                      .position.justifyContent
                                                }
                                                alignItems={
                                                   findStyle(active, "logo")
                                                      .position.alignItems
                                                }
                                                sx={{
                                                   height: "100%",
                                                   boxSizing: "border-box",
                                                   textAlign:
                                                      textAlignConverter[
                                                         findStyle(
                                                            active,
                                                            "logo"
                                                         ).position.alignItems
                                                      ],
                                                   p: findStyle(active, "logo")
                                                      .position.p,
                                                }}
                                             >
                                                <AnimatePresence>
                                                   {styleRender(
                                                      findStyle(active, "logo")
                                                   )}
                                                </AnimatePresence>
                                             </Stack>
                                          </Box>
                                       </Container>
                                    ))}
                                 </Box>
                              )}
                           </motion.div>
                        )}
                  </AnimatePresence>

                  <AnimatePresence>
                     {activeSlide.type === "thanks" &&
                        viewerLayout !== "lowerthird" && (
                           <motion.div
                              key='thanksLayout'
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              exit={{ opacity: 0, transition: { delay: 0.5 } }}
                              style={{
                                 position: "absolute",
                                 top: 0,
                                 left: 0,
                                 width: 1920,
                                 height: 1080,
                              }}
                           >
                              {layout && layout.styles && layoutFields && (
                                 <Box
                                    className={edit ? "editMode" : "viewMode"}
                                    key={activeSlide}
                                 >
                                    {["background", "thankyou"].map(
                                       (active) => (
                                          <Container
                                             edit={edit}
                                             name={
                                                findStyle(active, "thanks").name
                                             }
                                             data={findStyle(active, "thanks")}
                                             zIndex={
                                                findStyle(active, "thanks")
                                                   .zIndex
                                             }
                                             grid={grid}
                                             lockAspectRatio={lockAspectRatio}
                                             previewScale={previewScale}
                                             handleUpdate={handleUpdate}
                                             handleAdjust={handleAdjust}
                                             handleActiveElement={
                                                handleActiveElement
                                             }
                                             key={
                                                findStyle(active, "thanks").name
                                             }
                                          >
                                             <Box
                                                className='container'
                                                sx={{
                                                   position: "absolute",
                                                   boxSizing: "border-box",
                                                   width: "100%",
                                                   height: "100%",
                                                }}
                                             >
                                                <Stack
                                                   justifyContent={
                                                      findStyle(
                                                         active,
                                                         "thanks"
                                                      ).position.justifyContent
                                                   }
                                                   alignItems={
                                                      findStyle(
                                                         active,
                                                         "thanks"
                                                      ).position.alignItems
                                                   }
                                                   sx={{
                                                      height: "100%",
                                                      boxSizing: "border-box",
                                                      fontFamily: findStyle(
                                                         active,
                                                         "thanks"
                                                      ).parameters.fontFamily,
                                                      textAlign:
                                                         textAlignConverter[
                                                            findStyle(
                                                               active,
                                                               "thanks"
                                                            ).position
                                                               .alignItems
                                                         ],
                                                      p: findStyle(
                                                         active,
                                                         "thanks"
                                                      ).position.p,
                                                   }}
                                                >
                                                   <AnimatePresence>
                                                      {styleRender(
                                                         findStyle(
                                                            active,
                                                            "thanks"
                                                         )
                                                      )}
                                                   </AnimatePresence>
                                                </Stack>
                                             </Box>
                                          </Container>
                                       )
                                    )}
                                 </Box>
                              )}
                           </motion.div>
                        )}
                  </AnimatePresence>

                  {/* <AnimatePresence>
                     {activeSlide.type === "thanks" &&
                        viewerLayout === "lowerthird" && (
                           <motion.div
                              key='lowerthirdThanksLayout'
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              exit={{ opacity: 0 }}
                              style={{
                                 position: "absolute",
                                 top: 0,
                                 left: 0,
                                 width: 1920,
                                 height: 1080,
                              }}
                           >
                              {layout && layout.styles && layoutFields && (
                                 <Box
                                    className={edit ? "editMode" : "viewMode"}
                                    key={activeSlide}
                                 >
                                    {["background", "name"].map((active) => (
                                       <Container
                                          edit={edit}
                                          name={
                                             findStyle(active, "lowerthird")
                                                .name
                                          }
                                          data={findStyle(active, "lowerthird")}
                                          zIndex={
                                             findStyle(active, "lowerthird")
                                                .zIndex
                                          }
                                          grid={grid}
                                          lockAspectRatio={lockAspectRatio}
                                          previewScale={previewScale}
                                          handleUpdate={handleUpdate}
                                          handleAdjust={handleAdjust}
                                          handleActiveElement={
                                             handleActiveElement
                                          }
                                          key={
                                             findStyle(active, "lowerthird")
                                                .name
                                          }
                                       >
                                          <Box
                                             className='container'
                                             sx={{
                                                position: "absolute",
                                                boxSizing: "border-box",
                                                width: "100%",
                                                height: "100%",
                                             }}
                                          >
                                             <Stack
                                                justifyContent={
                                                   findStyle(
                                                      active,
                                                      "lowerthird"
                                                   ).position.justifyContent
                                                }
                                                alignItems={
                                                   findStyle(
                                                      active,
                                                      "lowerthird"
                                                   ).position.alignItems
                                                }
                                                sx={{
                                                   height: "100%",
                                                   boxSizing: "border-box",
                                                   fontFamily: findStyle(
                                                      active,
                                                      "lowerthird"
                                                   ).parameters.fontFamily,
                                                   textAlign:
                                                      textAlignConverter[
                                                         findStyle(
                                                            active,
                                                            "lowerthird"
                                                         ).position.alignItems
                                                      ],
                                                   p: findStyle(
                                                      active,
                                                      "lowerthird"
                                                   ).position.p,
                                                }}
                                             >
                                                <AnimatePresence>
                                                   {styleRender(
                                                      findStyle(
                                                         active,
                                                         "lowerthird"
                                                      )
                                                   )}
                                                </AnimatePresence>
                                             </Stack>
                                          </Box>
                                       </Container>
                                    ))}
                                 </Box>
                              )}
                           </motion.div>
                        )}
                  </AnimatePresence> */}

                  <AnimatePresence>
                     {activeSlide.type === "bidder" && (
                        <motion.div
                           key='bidderLayout'
                           initial={{ opacity: 0 }}
                           animate={{ opacity: 1 }}
                           exit={{ opacity: 0, transition: { delay: 0.5 } }}
                        >
                           {layout && layoutFields && (
                              <Box
                                 className={edit ? "editMode" : "viewMode"}
                                 key={activeSlide}
                              >
                                 {layoutFields.map((active) => (
                                    <Container
                                       edit={edit}
                                       name={
                                          findStyle(active, viewerLayout).name
                                       }
                                       data={findStyle(active, viewerLayout)}
                                       zIndex={
                                          findStyle(active, viewerLayout).zIndex
                                       }
                                       grid={grid}
                                       lockAspectRatio={lockAspectRatio}
                                       previewScale={previewScale}
                                       handleUpdate={handleUpdate}
                                       handleAdjust={handleAdjust}
                                       handleActiveElement={handleActiveElement}
                                       key={
                                          findStyle(active, viewerLayout).name
                                       }
                                    >
                                       <Box
                                          className='container'
                                          sx={{
                                             position: "absolute",
                                             boxSizing: "border-box",
                                             width: "100%",
                                             height: "100%",
                                          }}
                                       >
                                          <Stack
                                             justifyContent={
                                                findStyle(active, viewerLayout)
                                                   .position.justifyContent
                                             }
                                             alignItems={
                                                findStyle(active, viewerLayout)
                                                   .position.alignItems
                                             }
                                             sx={{
                                                height: "100%",
                                                boxSizing: "border-box",
                                                fontFamily: findStyle(
                                                   active,
                                                   viewerLayout
                                                ).parameters.fontFamily,
                                                textAlign:
                                                   textAlignConverter[
                                                      findStyle(
                                                         active,
                                                         viewerLayout
                                                      ).position.alignItems
                                                   ],
                                             }}
                                          >
                                             <AnimatePresence>
                                                {styleRender(
                                                   findStyle(
                                                      active,
                                                      viewerLayout
                                                   )
                                                )}
                                             </AnimatePresence>
                                          </Stack>
                                       </Box>
                                    </Container>
                                 ))}
                              </Box>
                           )}
                        </motion.div>
                     )}
                  </AnimatePresence>
               </>
            )}
         </Box>
         <Box
            className={edit ? "transparentBG" : ""}
            sx={{
               position: "absolute",
               top: 0,
               left: 240,
               width: 1920 * previewScale,
               height: 1080 * previewScale,
               zIndex: -1,
            }}
         />
      </>
   );
}
